// colours
$accent-1:					#3f4444;
$accent-2:					#84754e;
$white:							#fff;
$black:							#000;
$grey:							#524e4e;
$green:							#008000;
$white-transparent:	rgba($color: $white, $alpha: .7);
$white-transparent-lighter: rgba($color: $white, $alpha: .4);
$white-transparent-lightest: rgba($color: $white, $alpha: .2)
