// Misc.
	$misc: (
		z-index-base:	10000
	);

// Duration.
	$duration: (
		navPanel: .5s
	);

// Size.
	$size: (
		navPanel: 275px
	);

// Font.
	$font: (
	);

// Palette.
	$palette: (
		bg:				#9fb3ce,
		bg-corporate: #84754e,
		bg-corporate-opacity: rgba(132, 117, 78, .3),
		fg:				#7c8081,
		border:			transparentize(#7c8081, .8),

		header: (
			bg:			#fff,
			fg:			inherit,
			alt-bg:		transparent,
			alt-fg:		#fff
		),

		banner: (
			bg:			#898786,
			fg:			#fff,
			inner-bg:	#341b2b,
			inner-fg:	#fff
		),

		footer: (
			bg:			#e8eef4,
			fg:			#7c8081
		),

		one: (
			bg:			#62b1a8,
			fg:			#fff
		),

		two: (
			bg:			#fff,
			fg:			inherit
		),

		three: (
			bg:			#645862,
			fg:			#fff
		),

		five: (
			bg:			#8c99cf,
			fg:			#fff
		),

		navPanel: (
			bg:			#1c2021,
			fg:			#fff
		),

		navButton: (
			bg:			rgba(163, 169, 170, .75),
			fg:			#fff
		),

		twitter: (
			bg:			#70aecd,
			fg:			#fff
		),

		facebook: (
			bg:			#7490c3,
			fg:			#fff
		),

		google-plus: (
			bg:			#db6b67,
			fg:			#fff
		),

		github: (
			bg:			#dcad8b,
			fg:			#fff
		),

		dribbble: (
			bg:			#da83ae,
			fg:			#fff
		)

	);
