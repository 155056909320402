@import 'libs/vars';
@import 'libs/colours';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'libs/custom';
@import url('/css/font-awesome.min.css');
//@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab:100,300,400,700');
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

//Twenty by HTML5 UP
//html5up.net | @ajlkn
//Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)

$pattern-background-size: 25em;

// Breakpoints.

@include breakpoints(
    (
        wide: (
            1281px,
            1680px,
        ),
        normal: (
            981px,
            1280px,
        ),
        narrow: (
            841px,
            980px,
        ),
        narrower: (
            737px,
            840px,
        ),
        mobile: (
            null,
            736px,
        ),
    )
);

// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font: montserrat, sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

nav {
    margin-left: auto;
    margin-right: auto;
    position: static;
    right: 20px;
    width: 460px;
    z-index: 999;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;

    &::before,
    &::after {
        content: '';
    }
}

mark {
    background-color: transparent;
    color: inherit;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input,
select,
textarea {
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

//Basic
// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    -webkit-text-size-adjust: none;
    line-height: 1;

    // Stops initial animations until page loads.
    &.is-preload {
        *,
        *::before,
        *::after {
            @include vendor('animation', 'none !important');
            @include vendor('transition', 'none !important');
        }
    }
}

body,
input,
select,
textarea {
    color: $black;
    font-family: montserrat , sans-serif;
    font-size: 15pt;
    font-weight: 100;
    letter-spacing: .025em;
    line-height: 1.75em;
}

a {
    @include vendor(
        'transition',
        (
            'color 0.2s ease-in-out',
            'border-color 0.2s ease-in-out',
            'background-color 0.2s ease-in-out'
        )
    );
    //border-bottom: dotted 1px;
    color: darken(_palette(one, bg), 20);
    text-decoration: none;

    &:hover {
        border-bottom-color: transparent;
    }
}

strong,
b {
    font-weight: 100;
}

p,
ul,
ol,
dl,
table,
blockquote {
    margin: 0 0 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-weight: 400;
    line-height: 1.75em;
    margin-bottom: 1em;
    //text-transform: uppercase;

    a {
        border: 0;
        color: inherit;
        text-decoration: none;
    }
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4em;
}

h1 {
    font-size: 1.5em;
    letter-spacing: .1em;
}

h2 {
    font-size: 1.5em;
    letter-spacing: .1em;
}

h3 {
    font-size: 1.15em;
    letter-spacing: .025em;
}

sub {
    font-size: .8em;
    position: relative;
    top: .5em;
}

sup {
    font-size: .8em;
    position: relative;
    top: -.5em;
}

hr {
    border: 0;
    border-top: solid 1px _palette(border);
    margin-bottom: 1.5em;
}

blockquote {
    border-left: solid .5em _palette(border);
    font-style: italic;
    padding: 1em 0 1em 2em;
}

// Container

.container {
    margin: 0 auto;
    max-width: 100%;
    width: 1400px;

    &.medium {
        width: (1400px * .75);
    }

    @include breakpoint('<=wide') {
        width: 1200px;

        &.medium {
            width: (1200px * .75);
        }
    }

    @include breakpoint('<=normal') {
        width: 960px;

        &.medium {
            width: (980px * .75);
        }
    }

    @include breakpoint('<=narrow') {
        width: 95%;

        &.medium {
            width: (95% * .75);
        }
    }

    @include breakpoint('<=narrower') {
        width: 95%;

        &.medium {
            width: 95%;
        }
    }

    @include breakpoint('<=mobile') {
        width: 100%;

        &.medium {
            width: 100%;
        }
    }
}

// Row

.row {
    @include html-grid((50px, 50px));

    @include breakpoint('<=wide') {
        @include html-grid((40px, 40px), 'wide');
    }

    @include breakpoint('<=normal') {
        @include html-grid((40px, 40px), 'normal');
    }

    @include breakpoint('<=narrow') {
        @include html-grid((30px, 30px), 'narrow');
    }

    @include breakpoint('<=narrower') {
        @include html-grid((30px, 30px), 'narrower');
    }

    @include breakpoint('<=mobile') {
        @include html-grid((30px, 30px), 'mobile');
    }
}

// Section/Article

section,
article {
    &.special {
        text-align: center;
    }
}

header {
    &.major {
        padding-bottom: 2em;
    }

    &.special {
        margin-bottom: 5em;
        position: relative;
        text-align: center;

        &::before,
        &::after {
            color: $accent-2;
            content: '';
            height: 7px;
            opacity: .5;
            position: absolute;
            top: 1.75em;
            width: 43%;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        h2 {
            margin-bottom: 0;
        }

        h2 + p {
            margin-bottom: 0;
            padding-top: 1.5em;
        }

        .icon {
		color: $accent-2;
            cursor: default;
            height: 7em;
            left: 0;
            position: absolute;
            text-align: center;
            top: 1em;
			width: 100%;

            &::before {
                font-size: 3.5em;
                opacity: .5;
            }
        }
    }
}

footer {
    > :last-child {
        margin-bottom: 0;
    }

    &.major {
        padding-top: 3em;
    }
}

// Form

input[type='text'],
input[type='password'],
input[type='email'],
textarea {

    @include vendor(
        'transition',
        ('background-color 0.2s ease-in-out', 'border-color 0.2s ease-in-out')
    );

    @include vendor('appearance', 'none');
    background: none;
    border: solid 1px $black;
    border-radius: 0;
    color: inherit;
    display: block;
    line-height: 1em;
    margin-top: 10px;
    outline: 0;
    padding: .75em;
    text-decoration: none;
    width: 100%;

    &:focus {
        border-color: _palette(one, bg);
    }
}

form {
    border: 1px solid $accent-2;
    margin: 0 0 30px;
    padding: 30px;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: .5;
    position: relative;
    top: 3px;
}

:-moz-placeholder {
    color: inherit;
    opacity: .5;
}

::-moz-placeholder {
    color: inherit;
    opacity: .5;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: .5;
}

// Image

.image {
    border: 0;
    position: relative;

    &::before {
        background: url('../images/overlay.png');
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.fit {
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }

    &.featured {
        display: block;
        margin: 0 0 2em;

        img {
            display: block;
            width: 100%;
        }
    }
}

// Icon

.icon {
    @include icon;
    position: relative;

    &.circle {
        @include vendor('transition', 'all 0.2s ease-in-out');
        border: 0;
        border-radius: 100%;
        display: inline-block;
        font-size: 1.25em;
        height: 2.25em;
        left: 0;
        line-height: 2.25em;
        text-align: center;
        text-decoration: none;
        top: 0;
        width: 2.25em;

        &:hover {
            top: -.2em;
        }

        &.fa-twitter {
            background: _palette(twitter, bg);
            color: _palette(twitter, fg);

            &:hover {
                background: lighten(_palette(twitter, bg), 4);
            }
        }

        &.fa-facebook {
            background: _palette(facebook, bg);
            color: _palette(facebook, fg);

            &:hover {
                background: lighten(_palette(facebook, bg), 4);
            }
        }

        &.fa-google-plus {
            background: _palette(google-plus, bg);
            color: _palette(google-plus, fg);

            &:hover {
                background: lighten(_palette(google-plus, bg), 4);
            }
        }

        &.fa-github {
            background: _palette(github, bg);
            color: _palette(github, fg);

            &:hover {
                background: lighten(_palette(github, bg), 4);
            }
        }

        &.fa-dribbble {
            background: _palette(dribbble, bg);
            color: _palette(dribbble, fg);

            &:hover {
                background: lighten(_palette(dribbble, bg), 4);
            }
        }
    }

    &.featured {
        cursor: default;
        display: block;
        margin: 0 0 1.5em;
        opacity: .35;
        text-align: center;

        &::before {
            font-size: 5em;
            line-height: 1em;
        }
    }

    > .label {
        display: none;
    }
}

// List

ol {
    list-style: decimal;
    padding-left: 1.25em;

    li {
        padding-left: .25em;
    }
}

ul {
    display: flex;
    list-style: none;
    padding-top: 20px;

    li {
        color: $white;
        padding: 0 .5em;

        & a {
            border-radius: 5px;
            color: $white;
            display: block;
            font-size: 12px;
            padding: 0 8px;
        }

        & a:hover {
            background-color: _palette(bg-corporate);
        }
    }
}

// Icons

ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;
        line-height: 1em;
        padding-left: .5em;

        &:first-child {
            padding-left: 0;
        }
    }
}

// Featured Icons

ul.featured-icons {
    cursor: default;
    list-style: none;
    margin: -.75em 0 0;
    opacity: .35;
    overflow: hidden;
    padding-left: 0;
    position: relative;

    li {
        display: block;
        float: left;
        padding-left: 0;
        text-align: center;
        width: 50%;

        .icon {
            display: inline-block;
            font-size: 6.25em;
            height: 1.25em;
            line-height: 1.25em;
            width: 1em;
        }
    }
}

// Buttons

ul.buttons {
    cursor: default;
    list-style: none;
    padding-left: 0;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        display: inline-block;
        padding: 0 0 0 1.5em;

        &:first-child {
            padding: 0;
        }
    }

    &.stacked {
        li {
            display: block;
            padding: 1.5em 0 0;

            &:first-child {
                padding: 0;
            }
        }
    }
}

// Table

table {
		border-collapse: collapse;
		border-spacing: 0;
        width: 100%;

    &.default {
        width: 100%;

        tbody {
            tr {
                border-bottom: solid 1px _palette(border);
            }
        }

        td {
            padding: .5em 1em;
        }

        th {
            font-weight: 400;
            padding: .5em 1em;
            text-align: left;
        }

        thead {
            background: _palette(fg);
            color: $white;
        }
    }
}

// Button

input[type='button'],
input[type='submit'],
input[type='reset'],
button,
.button {
    @include vendor('appearance', 'none');
    @include vendor(
        'transition',
        (
            'color 0.2s ease-in-out',
            'border-color 0.2s ease-in-out',
            'background-color 0.2s ease-in-out'
        )
    );
    background: none;
    border: solid 1px;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 3.75em;
    padding: 0 .75em;
    text-align: center;
    text-decoration: none;

    &:hover {
        background: $green;
        border-color: inherit;
    }

    &.primary {
        background: _palette(one, bg);
        border-color: _palette(one, bg);
        color: _palette(one, fg) !important;

        &:hover {
            background: $green !important;
            border-color: lighten(_palette(one, bg), 5) !important;
        }
    }

    &.fit {
        width: 100%;
    }

    &.small {
        font-size: .7em;
        min-width: 14em;
        padding: .5em 0;
    }
}

// Wrapper

.wrapper {
    padding: 5em;

    &.style1 {
        padding: 4em;
        text-align: center;
    }

    &.style2 {
        background-color: _palette(one, bg);
        background-image: none;
				color: _palette(one, fg);
				text-align: center;

        input[type='button'],
        input[type='submit'],
        input[type='reset'],
        button,
        .button {
            &:hover {
                background: rgba(255, 255, 255, .15) !important;
            }

            &.primary {
                background: _palette(one, fg);
                border-color: _palette(one, fg);
                color: _palette(one, bg) !important;

                &:hover {
                    border-color: inherit !important;
                    color: _palette(one, fg) !important;
                }
            }
        }
    }

    &.style3 {
        background: _palette(two, bg);
				color: _palette(two, fg);
				margin-top: 60px;
    }

    &.style4 {
        background: _palette(two, bg);
        color: _palette(two, fg);
        padding: 4em;
		}

    &.style5 {
        background: _palette(five, bg);
        background-image: url('../images/iphone-transparent-3.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        color: _palette(five, fg);
        padding: 4em;
    }

    &.style6 {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%); // FF3.6-15
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%); // Chrome10-25,Safari5.1-6
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
        color: _palette(two, bg);
        padding: 0 2em 2em;
        }

    &.style-triangles {
        background-size: auto;
        color: _palette(one, fg);
    }

@media only screen and (max-width: 520px) {
    &.style3 {
        padding: 2em 1em;
    }

    &.style4 {
        padding: 2em 1em;
    }

    &.style5 {
        padding: 2em 1em;
    }

    &.style6 {
        padding: 0 2em 2em;
    }
}
}

// Header

@include keyframes('reveal-header') {
    0% {
        top: -5em;
    }

    100% {
        top: 0;
    }
}

#header {
    background: $accent-1;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .075);
    color: $white;
    cursor: default;
    font-size: .8em;
    left: 0;
    padding: 1em 1.5em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    h1 {
        font-weight: 900;
        margin: 0;

        span {
            font-weight: 300;
        }
    }

    nav {
        letter-spacing: .075em;
        position: absolute;
        right: 1.5em;
        text-transform: uppercase;
        top: 2em;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                background-color: $accent-1;
                display: inline-block;
                margin-left: 1.5em;
                padding-left: 0;

                > ul {
                    display: none;
                }

                a {
                    border: solid 1px transparent;
                    color: inherit;
                    display: inline-block;
                    font-weight: 300;
                    line-height: 1em;
                    padding: .6em .75em;
                    text-decoration: none;
                }

                input[type='button'],
                input[type='submit'],
                input[type='reset'],
                button,
                .button {
                    font-size: 1em;
                    min-width: 0;
                    width: auto;
                }

                &.submenu {
                    > a {
                        @include icon;

                        &::before {
                            content: '\f107';
                            margin-right: .65em;
                        }
                    }
                }

                &.active,
                &:hover {
                    > a {
                        @include vendor('transition', 'all 0.2s ease-in-out');
                        background: rgba(188, 202, 206, .15);
                    }
                }

                &.current {
                    > a {
                        font-weight: 900;
                    }
                }
            }
        }
    }

    &.reveal {
        @include vendor('animation', 'reveal-header 0.5s');
    }

    &.alt {
        @include vendor('animation', 'none');
        background: _palette(header, alt-bg);
        box-shadow: none;
        color: _palette(header, alt-fg);
        padding: 2em 2.5em;
        position: absolute;

        nav {
            right: 2.5em;
            top: 3em;

            ul {
                li {
                    &.active,
                    &:hover {
                        > a {
                            border: solid 1px;
                        }
                    }
                }
            }
        }
    }
}

// Dropotron

.dropotron {
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .075);
    line-height: 2.25em;
    list-style: none;
    margin-top: calc(-1em + 1px);
    min-width: 13em;
    padding: 1em 0;
    padding-left: 0;
    //text-transform: uppercase;

    &.level-0 {
        font-size: .7em;
        font-weight: 400;
        margin-top: 1.5em;

        &::before {
            border-bottom: solid .5em $white;
            border-left: solid .5em transparent;
            border-right: solid .5em transparent;
            content: '';
            left: .75em;
            position: absolute;
            top: -.45em;
        }
    }

    > li {
        border-top: solid 1px _palette(border);
        padding-left: 0;

        > a {
            @include vendor('transition', 'none');
            border: 0;
            color: inherit;
            padding: 0 1em;
            text-decoration: none;
        }

        &:hover {
            > a {
                background: _palette(one, bg);
                color: _palette(one, fg);
            }
        }

        &:first-child {
            border-top: 0;
        }
    }
}

// Banner
@include keyframes('reveal-banner') {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#banner {
    background-attachment: scroll;
    background-color: _palette(three, bg);
    background-position: top center !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    color: _palette(banner, fg);
    cursor: default;
    padding: 6em 0;
    text-align: center;

    .inner {
        @include vendor('animation', 'reveal-banner 1s 0.25s ease-in-out');
        @include vendor('animation-fill-mode', 'forwards');
        color: _palette(banner, inner-fg);
        display: inline-block;
        opacity: 0;
        padding: 3em;
        text-align: center;

        header {
            display: inline-block;
            //border-bottom: solid 2px;
            //border-top: solid 2px;
            margin: 0 0 2em;
            padding: 3px 0;

            h1, h2 {
                border-bottom: solid 2px;
                border-top: solid 2px;
                font-size: 2.5em;
                font-weight: 900;
                letter-spacing: .2em;
                margin: 0;
                padding-left: .05em;
                position: relative;
                //text-transform: uppercase;
            }
        }

        p {
            letter-spacing: .1em;
            margin: 0;
            //text-transform: uppercase;

            a {
                color: inherit;
                font-weight: 400;
                text-decoration: none;
            }
        }

        footer {
            margin: 2em 0 0;
        }
    }
}

// Main

#main {
    padding: 0;

    > :last-child {
        margin-bottom: 0;
    }

    .sidebar {
        section {
            border-top: solid 1px _palette(border);
            margin: 3em 0 0;
            padding: 3em 0 0;

            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

body.index #main {
    padding-top: 5em;
}

// CTA

#cta {
    background-attachment: scroll, scroll, scroll, fixed;
    background-color: _palette(three, bg);
    background-image: url('../images/light-tl.svg'),
        url('../images/light-tr.svg'), url('../images/overlay.png'),
        url('../../images/banner.jpg');
    background-position: top left, top right, top left, bottom center;
    background-repeat: no-repeat, no-repeat, repeat, no-repeat;
    background-size: $pattern-background-size, $pattern-background-size, auto,
        cover;
    color: _palette(three, fg);
    padding: 5em;
    text-align: center;

    header {
        margin-bottom: 2em;
    }
}

// Footer

#footer {
	background-attachment: scroll, scroll, scroll, fixed;
	background-color: $accent-1;
	color: _palette(three, fg);
	padding: 5em;
    text-align: center;

    ul {
        display: block;
    }

    .copyright {
        font-size: 1em;
        line-height: 1.6em;

        a {
            color: inherit;
        }

        li {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            &:first-child {
                border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

// Wide

@include breakpoint('<=wide') {
    // Basic

    body,
    input,
    select,
    textarea {
        font-size: 14pt;
    }

    // Section/Article

    header {
        &.special {
            margin-bottom: 4em;
            //padding-top: 5.5em;
        }
    }
}

// Normal

@include breakpoint('<=normal') {
    // Basic

    body,
    input,
    select,
    textarea {
        font-size: 13pt;
        letter-spacing: .025em;
        line-height: 1.65em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
		line-height: 1.5em;
    }

    // Section/Article

    header {
        &.major {
            padding-bottom: 1.5em;
        }
    }

    footer {
        &.major {
            padding-top: 2em;
        }
    }

    // Wrapper

    .wrapper {
        padding: 4em 3em;

        &.style4 {
            padding: 3em;
        }
    }

    // Header

    #header {
        nav {
            ul {
                li {
                    margin-left: 1em;
                }
            }
        }
    }

    // Banner

    #banner {
        background-attachment: scroll;
    }

    // CTA

    #cta {
        background-attachment: scroll;
        padding: 4em;
    }

    // Footer

    #footer {
        padding: 4em;
    }
}

// Narrow

@include breakpoint('<=narrow') {
    $pattern-background-size: 15em;

    // Basic

    body,
    input,
    select,
    textarea {
        font-size: 13pt;
        letter-spacing: .025em;
        line-height: 1.5em;
    }

    // Section/Article

    header {
        br {
            display: none;
        }

        &.major {
            padding-bottom: 1em;
        }

        &.special {
            padding-left: 2.5em;
            padding-right: 2.5em;
        }
    }

    footer {
        &.major {
            padding-top: 1.5em;
        }
    }

    // Wrapper

    .wrapper {
        padding: 3em 2.5em;

        &.special {
            br {
                display: none;
            }
        }

        &.style1 {
            padding: 2em 2.5em;
        }

        &.style2 {
            background-size: $pattern-background-size;
        }

        &.style4 {
            padding: 2.5em;
        }
    }

    // Main

    #main {
        background-size: $pattern-background-size;
    }

    // CTA

    #cta {
        background-size: $pattern-background-size, $pattern-background-size,
            auto, cover;
        padding: 3em;
    }
}

// Narrower

#navPanel,
#navButton {
    display: none;
}

@include breakpoint('<=narrower') {
    // Basic

    html,
    body {
        overflow-x: hidden;
    }

    header {
        &.major {
            padding-bottom: .25em;
        }

        &.special {
            margin-bottom: 4em;
            padding-top: 0;

            &::before,
            &::after {
                width: 40%;
            }

            h2 + p {
                padding-top: 1.25em;
            }
        }
    }

    // Section/Article

    section {
        margin: 0;
    }

    // Button

    input[type='button'],
    input[type='submit'],
    input[type='reset'],
    button,
    .button {
        &.small {
            font-size: .8em;
            min-width: 18em;
            padding: .75em 0;
        }
    }

    // Featured Icons //

    ul.featured-icons {
        margin: 0;

        li {
            display: inline-block;
            float: none;
            width: auto;

            .icon {
                font-size: 4em;
                width: 1.25em;
            }
        }
    }

    // Buttons

    ul.buttons {
        li {
            display: block;
            padding: 1em 0 0;
        }
    }

    // Header

    #header {
        display: none;
    }

    // Banner

    #banner {
        margin: 0;
    }

    // Wrapper

    .wrapper {
        &.special-alt {
            text-align: center;
        }

        &.style4 {
            padding-bottom: 3em;
        }
    }

    // Main

    #main {
        padding: 3em 0 0;

        .sidebar {
            border-top: solid 1px transparentize(_palette(fg), .9);
            padding-top: 3em;

            section {
                border-top: 0;
                padding-top: 0;
            }
        }
    }

    body.index #main {
        padding-top: 4.5em;
    }

    // CTA

    #cta {
        margin: 0;
    }

    // Footer

    #footer {
        padding: 4em 1.5em;
    }

    // Nav

    #page-wrapper {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transition', 'transform #{_duration(navPanel)} ease');
        padding-bottom: 1px;
    }

    #navButton {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transition', 'transform #{_duration(navPanel)} ease');
        display: block;
        height: 60px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: _misc(z-index-base) + 1;

        .toggle {
            @include icon;
            border: 0;
            height: 60px;
            left: 0;
            outline: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;

            &::before {
                color: _palette(navButton, fg);
                content: '\f0c9';
                font-size: 1em;
                height: 40px;
                left: 10px;
                line-height: 40px;
                opacity: .5;
                position: absolute;
                top: 11px;
                width: 60px;
                z-index: 1;
            }

            &::after {
                background: _palette(navButton, bg);
                border-radius: 2px;
                content: '';
                height: 40px;
                left: 10px;
                position: absolute;
                top: 10px;
                width: 60px;
            }
        }
    }

    #navPanel {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
        @include vendor(
            'transition',
            ('transform #{_duration(navPanel)} ease')
        );
        background: _palette(navPanel, bg);
        color: _palette(navPanel, fg);
        display: block;
        font-size: .8em;
        height: 100%;
        left: 0;
        letter-spacing: .075em;
        overflow-y: auto;
        padding: .25em .75em 1em;
        position: fixed;
        text-transform: uppercase;
        top: 0;
        width: _size(navPanel);
        z-index: _misc(z-index-base) + 2;

        .link {
            border: 0;
            border-top: solid 1px transparentize(_palette(navPanel, fg), .95);
            color: inherit;
            display: block;
            height: 3em;
            line-height: 3em;
            opacity: .75;
            text-decoration: none;

            &.depth-0 {
                font-weight: 900;
            }

            &:first-child {
                border-top: 0;
            }
        }

        .indent-1 {
            display: inline-block;
            width: 1em;
        }

        .indent-2 {
            display: inline-block;
            width: 2em;
        }

        .indent-3 {
            display: inline-block;
            width: 3em;
        }

        .indent-4 {
            display: inline-block;
            width: 4em;
        }

        .indent-5 {
            display: inline-block;
            width: 5em;
        }
    }

    body {
        &.navPanel-visible {
            #page-wrapper {
                @include vendor('transform', 'translateX(#{_size(navPanel)})');
            }

            #navButton {
                @include vendor('transform', 'translateX(#{_size(navPanel)})');
            }

            #navPanel {
                @include vendor('transform', 'translateX(0)');
            }
        }
    }
}

// Mobile

@include breakpoint('<=mobile') {
    $pattern-background-size: 10em;

    // Basic

    body {
        min-width: 320px;
    }

    h1 {
        font-size: 1.25em;
        letter-spacing: .1em;
    }

    h2 {
        font-size: 1.25em;
        letter-spacing: .1em;
    }

    h3 {
        font-size: 1em;
        letter-spacing: .025em;
    }

    // Section/Article

    header {
        text-align: center;

        &.major {
            padding-bottom: 0;
        }

        &.special {
            margin-bottom: 3em;
            padding-left: 1.5em;
            padding-right: 1.5em;

            &::before,
            &::after {
                width: 38%;
            }

            .icon {
                font-size: .75em;
                top: 1.5em;
            }
        }

        p {
            text-align: center;
        }
    }

    footer {
        &.major {
            padding-top: 0;
        }
    }

    // Icon

    .icon {
        &.circle {
            font-size: 1em;
        }
    }

    // Button

    input[type='button'],
    input[type='submit'],
    input[type='reset'],
    button,
    .button {
        max-width: 20em;
        width: 100%;

        &.fit {
            width: auto;
        }
    }

    // Icons

    ul.icons {
        li {
            padding-left: .25em;
        }
    }

    // Featured Icons

    ul.featured-icons {
        li {
            .icon {
                width: 1.1em;
            }
        }
    }

    // Buttons

    ul.buttons {
        text-align: center;
    }

    // Wrapper

    .wrapper {
        margin-bottom: 0;
        padding: 2.25em 1.5em;

        &.special {
            br {
                display: none;
            }
        }

        &.style1 {
            padding: 2em 1.5em;
        }

        &.style2 {
            background-size: $pattern-background-size;
            padding: 2em 1em;
        }

        &.style3 {
            margin-top: 0;
        }

        &.style4 {
            background-size: $pattern-background-size;
            padding: 1.5em 1.5em 3em;
        }
    }

    // Banner

    #banner {

        .inner {
            background: none;
            display: block;
            padding: 0 1.5em;

            header {
                h1,
                h2 {
                    font-size: 1.5em;
                }
            }

            p {
                text-align: center;
            }

            br {
                display: none;
            }
        }
    }

    // Main

    #main {
        background-size: $pattern-background-size;
        padding: 3.5em 0 0;
    }

    body.index #main {
        padding: 2.5em 0 0;
    }

    body.contact #main {
        padding-bottom: 0;
    }

    // CTA

    #cta {
        background-size: $pattern-background-size, $pattern-background-size,
            auto, cover;
        padding: 3em 1.5em;
    }

    // Footer

    #footer {
        padding: 3em 1.5em;

        .copyright {
            li {
                border: 0;
                display: block;
                margin: 1em 0 0;
                padding: 0;
            }
        }
    }

    // Nav

}

#navButton {
    .toggle {
        &::before {
            height: 34px;
            left: 8px;
            line-height: 34px;
            top: 8px;
            width: 35px;
        }

        &::after {
            height: 34px;
            left: 8px;
            top: 8px;
            width: 35px;
        }
    }
}
