html {
	scroll-behavior: smooth;
}

#page-wrapper {
    background-attachment: fixed;
    background-image: url('../images/Triangle-background-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    position: relative;
}

.page-inner {
    margin: 0 auto;
}

#header {
    margin: 0 auto;
    max-width: 1200px;
}

.clearfix {
    clear: both;
}

@media screen and (max-width: 439px) {
    .nav-outer {
        display: none;
    }
}

.nav-logo {
    margin: 5px 0 20px;
    width: 260px;
}

.header-message {
    background-color: $white-transparent;
    border-radius: 10px;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    margin: 0 10%;
    padding: 0 20px 30px;
    position: absolute;
    text-align: center;
    top: 15%;
    width: 80%;
}

.header-content {
    display: block;
}

.button.fit {
    width: 200px !important;
}

.testimonial {
    margin: 0;
}

.stars {
    padding: 10px 10px 40px;
}

@media screen and (max-width: 840px) {
    .home-logo {
        align-content: center;
        background: $accent-1;
        color: $white;
        cursor: default;
        font-size: .8em;
        left: 0;
        padding: 1em 1.5em;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10000;

        & .mobile-logo {
            margin-left: auto;
            margin-right: auto;
            max-width: 250px;
        }
    }
}

.home-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#banner {
    padding: 4em 0 !important;
}

.imp-narrower {
    display: none;
}

.header-message-clear {
    color: $white;
    font-weight: 400;
    margin: 0 auto;
    padding: 100px 0 20px;
    text-align: center;
    top: 20px;
    width: 80%;
}

@media screen and (min-width: 2100px) {
    .header-message-clear {
        width: 60%;
    }

    .wrapper.style-triangles {
        margin: 0 auto;
        width: 65%;
    }

    form {
        margin: 0 auto !important;
        width: 65%;
    }
}

.wrapper {
    &.style-app {
        background-color: $accent-2;
		color: _palette(one, fg);
    }

	&.style-app2 {
		background-color: $accent-1;
		color: _palette(one, fg);
		margin-bottom: 0;
	}
}

.header-image {
    background-size: cover;
    height: 700px;
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 400px) {
    .header-image {
        height: 600px;
    }
}

@media only screen and (min-width: 600px) {
    .header-image {
        height: 500px;
    }
}

@media only screen and (min-width: 600px) {
.wrapper {
	&.style2 {
		background-repeat: no-repeat, no-repeat;
		background-size: cover;
		color: _palette(three, fg);
	}
}

	.imp-narrower {
		display: block;
	}

	#banner {
		padding: 5em 0;
	}

	.header-message {
        font-size: 18px;
        margin-top: 10px;
}
}

@media only screen and (min-width: 840px) {
    .home-logo {
        display: none;
    }

    .header-image {
        top: 0;
    }

    .header-message {
        font-size: 32px;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1400px) {
    .header-message {
        font-size: 60px;
    }
}

.app-img {
    background-image: url('../images/App_Laptop_Dashboard.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.app2-img {
    background-image: url('../images/App-tablet.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.block-panel {
    padding: 0 0 20px !important;
}

.block-panel-bottom {
    padding: 0 !important;
}

.block-inner-text {
    padding: 20px 20px 20px 40px !important;
}

.block-inner-text-right {
    padding: 60px 40px 40px !important;
}

@media screen and (max-width: 980px) {
    .wrapper.style2 {
        background-size: cover;
    }
}

@media screen and (max-width: 840px) {
    .app-img {
        display: none;
    }

    .app2-img {
        display: none;
    }

    .testimonials-desktop {
        display: none;
    }

    #banner {
        position: relative;
        top: 90px !important;
    }
}

.form-block {
    background-color: $white;
}

.contact-form input[type='text'] {
    border: solid 1px $white;
}

.contact-form textarea {
    border: solid 1px $white;
}

.contact-button {
    background-color: $accent-2;
    border: 0;
    color: $white;
    font-family: montserrat, sans-serif;
}

.accreditation-block {
    background-color: $white;
}

.retirement-block {
    background-image: url('/images/Retirement.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.investment-block {
    background-image: url('/images/Investment.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.balance-block {
    background-image: url('/images/Balance.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.panel-text-block {
    background-color: $white-transparent-lighter;
    border-radius: 10px;
    color: $black;
    font-size: 18px;
    font-weight: 300;
    margin: 150px 3em;
    padding: 30px 30px 10px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .panel-text-block {
        margin: 70px 1em;
    }
}

@media screen and (max-width: 420px) {
    .panel-text-block {
        margin: 20px 0;
    }

    .retirement-block {
        background-image: url('/images/Retirement-mobile.jpg');
    }

    .investment-block {
        background-image: url('/images/Investment-mobile.jpg');
    }

    .balance-block {
        background-image: url('/images/Balance-mobile.jpg');
        background-position: bottom left;
    }
}

.plain-block {
    text-align: center;

    &.portal {
        color: $black;
        padding-top: 430px;
    }
}

@media screen and (max-width: 600px) {
    .plain-block {
        text-align: center;

        &.portal {
            color: $black;
            padding-top: 170px;
        }
    }
}

@media screen and (min-width: 601px) {
    .plain-block {
        text-align: center;

        &.portal {
            color: $black;
            padding-top: 330px;
        }
    }
}

.phone-block {
    background-image: url('/images/iphone-transparent.png');
    text-align: center;
}

.link-white {
    color: $white;
}

.app-store-icons {
    max-width: 180px;
}

.portal-image {
    max-width: 700px;
}

.hooplelink {
    font-size: .8em;

    & a {
        background-color: transparent;
    }

    & a:hover {
        background-color: transparent;
        color: $accent-2 !important;
    }
}

.about-intro {
    color: $accent-1;
    padding: 0 1em 2em;
    text-align: center;
}

.services-header {
    font-size: 14px;
}

.services-body {
    font-size: 14px;
    margin: 0;
}

.services-block {
    margin-bottom: 0;
    top: 0;
}

.services-block-content {
    background-color: $white;
    border-radius: 10px;
    color: $black;
    font-weight: 400;
    margin: 0;
    padding: 20px;
    position: relative;
    text-align: center;
    top: 3%;
    width: 100%;
}

@media screen and (min-width: 400px) {
    .header-message {
        top: 27%;
    }

    .services-block-content {
        top: 10%;

        & .services-header {
            font-size: 20px;
        }

        & .services-body {
            font-size: 20px;
            margin: 0;
        }
    }
}

@media screen and (min-width: 460px) {
    .header-message {
        top: 25%;
    }

    .services-block-content {
        top: 25%;
    }
}

@media screen and (min-width: 600px) {
    .services-block-content {
        background-color: rgba(255, 255, 255, .7);
        margin: 0 5%;
        position: absolute;
        width: 90%;

        & .services-header {
            font-size: 24px;
        }

        & .services-body {
            font-size: 24px;
            line-height: 1.4em;
        }
    }
}

@media screen and (min-width: 760px) {
    .services-block-content {
        top: 30%;
    }
}

.badge {
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
    text-align: center;
    width: 100%;

    & img {
        max-width: 300px;
    }
}

.icon-logo {
    margin-bottom: -7px;
    width: 20px;
}

@media screen and (max-width: 460px) {
    .email-address {
        font-size: 12px;
    }
}

// Mobile nav

@media screen and (min-width: 439px) {
    .navi-toggle {
        display: none;
    }
}

// imports
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

// colors
$aqua: #84754e;
$plum: #5634d1;

// variables
$html-background: $plum;
$html-font: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$nav-toggle-bar-color: #84754e;
$nav-toggle-bar-height: .2rem;
$nav-toggle-bar-width: 1.5rem;
$nav-background: $aqua;
$nav-color: #3f4444;
$nav-width: 15rem;
$z-nav-toggle: 2;
$z-nav: 1;

// mixins
@mixin box-sizing($box-sizing: border-box) {
	-moz-box-sizing: $box-sizing;
	-webkit-box-sizing: $box-sizing;
	box-sizing: $box-sizing;
}

@mixin center-vertically($position: absolute) {
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}

@mixin text-size-adjust {
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

@mixin transform($transform) {
	-ms-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
}

@mixin transition($parameters: all .5s ease) {
	-moz-transition: $parameters;
	-ms-transition: $parameters;
	-o-transition: $parameters;
	-webkit-transition: $parameters;
    opacity: .9;
    transition: $parameters;
}

@mixin user-select {
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

// resets and base styles
* {
	@include box-sizing;
	@include text-size-adjust;
	&:focus { outline: none; }
}

// nav toggle
.navi-toggle {
	@include user-select;
	cursor: pointer;
	height: 5 * $nav-toggle-bar-height;
	left: 2rem;
	position: fixed;
	top: 2rem;
	width: $nav-toggle-bar-width;
	z-index: $z-nav-toggle;
    &:hover { opacity: .8; }

	.navi-toggle-bar,
	.navi-toggle-bar::after,
	.navi-toggle-bar::before {
		@include center-vertically;
		@include transition(all .5s ease);
		background: $nav-toggle-bar-color;
		content: '';
		height: $nav-toggle-bar-height;
		width: 100%;
	}
	.navi-toggle-bar { margin-top: 0; }
	.navi-toggle-bar::after { margin-top: 2 * $nav-toggle-bar-height; }
	.navi-toggle-bar::before { margin-top: -2 * $nav-toggle-bar-height; }
    &.expanded .navi-toggle-bar { background: transparent; }

	&.expanded .navi-toggle-bar::after,
	&.expanded .navi-toggle-bar::before {
		background: $nav-color;
		margin-top: 0;
	}
	&.expanded .navi-toggle-bar::after { @include transform(rotate(45deg)); }
	&.expanded .navi-toggle-bar::before { @include transform(rotate(-45deg)); }
}

// nav
.navi {
	@include transition(left .5s ease);
	background: $nav-background;
	color: $nav-color;
    cursor: pointer;
	font-size: 2rem;
	height: 100vh;
	left: -$nav-width;
	padding: 6rem 2rem 2rem;
	position: fixed;
	top: 0;
	width: $nav-width;
	z-index: $z-nav;
	&.expanded { left: 0; }

	ul {
		@include center-vertically;
		list-style: none;
		margin: 0;
		padding: 0;
    }

    a {
        color: $white;
    }
}

// Desktop nav

.nav-outer {
	height: 84px;
}

.table {
	display: table;   // Allow the centering to work
	margin: 0 auto;
}

ul.horizontal-list {
	list-style: none;
    padding: 20px 20px 0 0;
    }

	ul.horizontal-list li {
		display: inline;
    }

    @media screen and (min-width: 1200px) {
        ul.horizontal-list li a {
            font-size: 18px;
        }

        p {
            font-size: 120% !important;
        }
    }

.navi span {
    display: block;
    font-size: 18px;
    line-height: 2em;
}

.email-label {
    padding: 0 0 0 30px !important;
}

@media screen and (min-width: 737px) {
    .email-label {
        padding: 30px 0 0 30px !important;
    }
}

@media screen and (min-width: 981px) {
    .email-label {
        padding: 40px 0 0 30px !important;
    }
}

@media screen and (min-width: 1681px) {
    .email-label {
        padding: 50px 0 0 30px !important;
    }
}
